.primary-container{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: flex;
    object-fit: contain;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}   
.primary-container-active{
    transition: background-color 0.5s ease;
    background-color: #12232e
    ;
    height: 100vh;
    width: 100%;
    display: flex;
    object-fit: contain;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.primary-heading-content{
    width: 80%;
    color: white;
}

.primary-heading-content-active{
    width: 80%;
    color: #12232e;
}

.primary-para-content{
    margin-top: 30px;
    width: 90%;
    color: white;
}

.primary-para-content-active{
    margin-top: 30px;
    width: 90%;
    color: #12232e;
}

.pg3-heading{
    text-align: center;
    font-weight: 700;
    letter-spacing: -0.023rem;
    font-size: 52.03px;
    font-family: charrington , sans-serif;
}

.pg3-para{
    text-align: center;
    font-weight: 500;
    font-size: 28.03px;
    /* letter-spacing: -0.055em; */
    font-family: charrington , sans-serif;
}

@media screen and (max-width: 960px) {
    .pg3-heading {
        text-align: center;
        font-weight: 500;
        font-size: 42.03px;
        /* letter-spacing: -0.055em; */
        font-family: charrington , sans-serif;
    }
  }
  
  @media screen and (max-width: 768px) {
    .pg3-heading {
        text-align: center;
        font-weight: 500;
        font-size: 38.03px;
        font-family: charrington , sans-serif;
    }
}

@media screen and (max-width: 960px) {
    .pg3-para {
        text-align: center;
        font-weight: 500;
        font-size: 28.03px;
        /* letter-spacing: -0.055em; */
        font-family: charrington , sans-serif;
    }
  }
  
  @media screen and (max-width: 768px) {
    .pg3-para {
        text-align: center;
        font-weight: 500;
        font-size: 19.03px;
        font-family: charrington , sans-serif;
    }
}